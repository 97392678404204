import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, Container, Drawer, IconButton, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginService } from "../auth";
import { TermoEntregaService } from "../entidades/materiais/termo-de-entrega";
import { srcFoto } from "../util/ferramentas";
import { SelecionaUnidade } from "./seleciona-pasta";

function UsuarioLogado() {
    const [aberto, setAberto] = useState(false);
    const usr = LoginService.usr;

    return (
        <>
            <Button color="inherit"
                onClick={() => setAberto(true)}>
                {usr?.nome}
                <Avatar sx={{ marginLeft: 0.5 }} src={srcFoto(usr?.foto)} />
            </Button>
            <Drawer
                anchor='right'
                open={aberto}
                onClose={() => setAberto(false)}
            >
                <Container>
                    <Button onClick={() => { LoginService.logout() }}>Logout</Button>
                </Container>
            </Drawer>
        </>
    )
}

export function BarraNavegacao() {
    const [dialogoAberto, setDialogoAberto] = useState(false);
    const [aberto, setAberto] = useState(false);
    let history = useHistory();

    const fecharMenu = (linkNavegacao?: string) => {
        setAberto(false);
        if (linkNavegacao) {
            history.push(linkNavegacao)
        }
    };

    const handleFechaDialogo = async (pastaId: string | undefined) => {
        if (pastaId) {
            const termo = await TermoEntregaService.criarSolicitacao(pastaId);
            if (termo.id) {
                history.push("/termo-de-entrega/" + termo.id);
            }
        }
        setDialogoAberto(false);
    }

    return (
        <>
            <AppBar position="sticky">
                <Toolbar style={{ minHeight: 32, justifyContent: "space-between" }}>
                    <Box>
                        <IconButton edge="start" color="inherit" onClick={() => setAberto(true)}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <UsuarioLogado />
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={aberto}
                onClose={() => fecharMenu()}
                color="inherit"
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                >
                    <List>
                        <ListItem button onClick={() => fecharMenu('/')}>
                            <ListItemText primary="Resumo" />
                        </ListItem>
                        <ListItem button onClick={() => fecharMenu('/recebimento-consumo')}>
                            <ListItemText primary="Recebimento Consumo" />
                        </ListItem>
                        <ListItem button onClick={() => fecharMenu('/termo-de-entrega')}>
                            <ListItemText primary="Termos de Entrega" />
                        </ListItem>
                        <ListItem button onClick={() => setDialogoAberto(true)}>
                            <ListItemText primary="Criar Solicitação" />
                        </ListItem>
                        {LoginService.temPermissao('Gestor Materiais') && <ListItem button onClick={() => fecharMenu('/consumo/cadastros')}>
                            <ListItemText primary="Eliminar Duplicados" />
                        </ListItem>}
                        {LoginService.temPermissao('Gestor Materiais') && <ListItem button onClick={() => fecharMenu('/permanente/recebimento')}>
                            <ListItemText primary="Recebimento Permanente" />
                        </ListItem>}
                    </List>
                </Box>
            </Drawer>
            <SelecionaUnidade open={dialogoAberto} handleClose={handleFechaDialogo} titulo="Selecione a unidade" />
        </>
    )
}
