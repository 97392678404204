import { http } from '../../util/ferramentas';
import { Pasta, PastaService } from '../pasta';
import { CadastroMaterialConsumoService } from './cadastro-consumo';
import { ItemResumo } from './item-resumo';

export interface ConsumoEmEstoque {
    pasta: string;
    material: number;
    total: number;
}

export interface NecessidadeMaterial {
    pasta: Pasta['id'];
    materialNome: ItemResumo['materialNome'];
    materialEspecificacao: ItemResumo['materialEspecificacao'];
    qnt: number;
}

class ClasseConsumoEmEstoqueService {
    porPasta: { [pasta: string]: ConsumoEmEstoque[] } = {}
    private inicializado: Promise<void> = this.carregaTodos();

    async carregaTodos() {
        try {
            let tds = await http.get<ConsumoEmEstoque[]>(`/api/estoque/material-consumo`).then(resp => resp.data);
            this.porPasta = {};
            for (let estoque of tds) {
                let idPasta = estoque.pasta;
                this.porPasta[idPasta] = this.porPasta[idPasta] || [];
                this.porPasta[idPasta].push(estoque);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async conteudoDaPasta(idPasta: string) {
        await this.inicializado;
        return this.porPasta[idPasta] || [];
    }

    async getAll() {
        //if (atualiza) this.inicializado = this.carregaTodos()
        //return axios.get<ConsumoEmEstoque[]>(`/api/material-consumo/estoque`).then(resp => resp.data);
        await this.inicializado;
        return Object.values(this.porPasta).flat();
    }

    async getAllAtualizado() {
        await this.carregaTodos();
        return Object.values(this.porPasta).flat();
    }

    async intoItemResumo(e: ConsumoEmEstoque): Promise<ItemResumo> {
        try {
            let [material, pasta] = await Promise.all([CadastroMaterialConsumoService.busca(e.material), PastaService.getById(e.pasta)]);
            //let pasta = await PastaService.getById(e.pasta);
            return new ItemResumo(
                /* localId: */               pasta.id,
                /* localNome: */             pasta.nome,
                /* materialId: */            material.id + 'c',
                /* materialNome: */          material.nome,
                /* materialEspecificacao: */ material.especificacao,
                /* consumo: */               e.total,
                /* otimo: */                 0,
                /* bom: */                   0,
                /* regular: */               0,
                /* inservivel: */            0,
                /* necessidade: */           0,
            );
        } catch(err) {
            console.log(err)
            console.log(e)
            return new ItemResumo()
        }
    }

    async salvaNecessidade(pasta: ItemResumo['localId'],
        materialNome: ItemResumo['materialNome'],
        materialEspecificacao: ItemResumo['materialEspecificacao'],
        qnt: number) {
        const material = {
            pasta,
            materialNome,
            materialEspecificacao,
            qnt,
        };

        return await http.post<NecessidadeMaterial>(`/api/necessidade-material`, material).then(resp => resp.data);
    }
}

export const ConsumoEmEstoqueService = new ClasseConsumoEmEstoqueService();
