import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { format } from 'date-fns'
import { http } from "../../util/ferramentas";

export interface TermoEntrega {
    id: number;
    origem: string;
    destino: string;
    responsavelEntrega?: number;
    responsavelRecebimento?: number;
    dataElaboracao?: string;
    dataRecebimento?: string;
    obs?: string;
}

export interface ItemConsumo {
    material: number;
    termo: number;
    quantidade: number;
}

function novoTermo(): TermoEntrega {
    return {
        id: 0,
        origem: '',
        destino: '',
        responsavelEntrega: 0,
    }
}

export const PASTA_ALMOX_GERAL = '194u';

class ClasseTermoEntregaService {
    async buscaRascunhos() {
        return await http.get<TermoEntrega[]>("/api/termo-de-entrega").then(resp => resp.data);
    }

    async buscaTermo(id: number) {
        return await http.get<TermoEntrega>("/api/termo-de-entrega/" + id).then(resp => resp.data);
    }

    async criar(pasta: string): Promise<TermoEntrega> {
        return await http.post<TermoEntrega>("/api/termo-de-entrega/criar/" + pasta).then(resp => resp.data);
    }

    async criarSolicitacao(pastaDestino: string): Promise<TermoEntrega> {
        const solicitacao = await http.post<TermoEntrega>("/api/termo-de-entrega/criar/" + PASTA_ALMOX_GERAL).then(resp => resp.data);
        solicitacao.destino = pastaDestino;
        await this.save(solicitacao)
        return solicitacao;
    }

    async save(termo: TermoEntrega) {
        return await http.post<TermoEntrega>("/api/termo-de-entrega/", termo).then(resp => resp.data);
    }

    async saveItemConsumo(item: ItemConsumo) {
        return await http.post<TermoEntrega>("/api/termo-de-entrega/item-consumo", item).then(resp => resp.data);
    }

    async adicionaItemConsumo(termo: number, material: number) {
        return await http.post<TermoEntrega>(`/api/termo-de-entrega/${termo}/item-consumo/${material}`, {}).then(resp => resp.data);
    }
    async removeItemConsumo(termo: number, material: number) {
        return await http.delete<boolean>(`/api/termo-de-entrega/${termo}/item-consumo/${material}`, {}).then(resp => resp.data);
    }

    async concluir(rascunho: TermoEntrega) {
        rascunho.dataRecebimento = format(Date.now(), 'yyyy-MM-dd')
        return await this.save(rascunho);
    }

    async enviarAlmox(rascunho: TermoEntrega) {
        rascunho.dataElaboracao = format(Date.now(), 'yyyy-MM-dd')
        return await this.save(rascunho);
    }
}
export const TermoEntregaService = new ClasseTermoEntregaService();

export function useTermoDeEntegaCompleto(id: number): [TermoEntrega, Dispatch<SetStateAction<TermoEntrega>>] {
    const [termo, setTermo] = useState(novoTermo());
    useEffect(() => { TermoEntregaService.buscaTermo(id).then(setTermo) }, [id]);
    return [termo, setTermo];
}

export function useTermoDeEntega(id: number): [TermoEntrega, Dispatch<SetStateAction<TermoEntrega>>] {
    const [termo, setTermo] = useState(novoTermo());
    useEffect(() => { TermoEntregaService.buscaTermo(id).then(setTermo) }, [id]);
    return [termo, setTermo];
}

export function useItemsConsumoDoTermo(id: number) {
    const [items, setItems] = useState([] as ItemConsumo[]);
    useEffect(() => {
        http.get<ItemConsumo[]>(`/api/termo-de-entrega/${id}/materiais-consumo`).then(r => setItems(r.data));
        //const socket = new WebSocket('ws://localhost:1234/d0278/materiais-back/v1/api/termo-de-entrega/socket-consumo');
        //const socket = new WebSocket(`wss://${window.location.host}/api/termo-de-entrega/socket-consumo`);
        const socket = new WebSocket(`wss://gw.sgi.ms.gov.br/d0278/materiais-back/v1/api/termo-de-entrega/socket-consumo`);
        socket.onopen = () => {
            socket.send('' + id);
        }
        socket.onmessage = (ev: MessageEvent<string>) => {
            const item: ItemConsumo = JSON.parse(ev.data);

            if (item.material > 0) {
                // O item foi adicionado
                setItems(is => [...is, item]);
            } else {
                // O item foi removido
                setItems(is => is.filter(i => i.termo !== item.termo || -i.material !== item.material))
            }
        };
        return () => { socket.close() }
    }, [id])
    return items;
}
