import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, Container, FormControl, Grid, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { ptBR } from "date-fns/locale";
import { forwardRef, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import {
    BrowserRouter as Router,
    Route, Switch
} from "react-router-dom";
import './App.css';
import { LoginService } from './auth';
import { BarraNavegacao } from './componentes/barra-de-navegacao';
import { Usuario } from './entidades/usuario';
import { TelaContabilidade } from './telas/materiais/contabilidade';
import { TelaDetalhesTermoDeEntrega } from './telas/materiais/detalhes-termo-de-entrega';
import { TelaEntregas } from './telas/materiais/entregas';
import { TelaGerenciaCadastroConsumo } from './telas/materiais/gerencia-cadastros-consumo';
//import { TelaCadastroPermanente } from './telas/materiais/permanente/cadastrar-permanente';
import { TelaRecebimentoPermanente } from './telas/materiais/permanente/recebimento-permanente';
import { TelaRecebimentoConsumo } from './telas/materiais/recebimento-consumo';
import { TelaResumo } from './telas/materiais/resumo';
import { TelaTermosDeEntrega } from './telas/materiais/termos-de-entrega';

const MascaraCPF = forwardRef<HTMLElement, any>(
    function MascaraCPF(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { value } })}
                overwrite
            />
        );
    },
);

function TelaLogin(props: { setUsuario: (u: any) => void }) {
    const [cpf, setCPF] = useState('');
    const [senha, setSenha] = useState('');

    //const titulo = <h3>Sou <strong>CBMMS</strong></h3>;
    const titulo = <h3>Sistema de Materiais</h3>;

    return (
        <Container fixed className="App">
            <form>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        {titulo}
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="cpf-mascarado" variant="outlined">CPF</InputLabel>
                            <OutlinedInput id="cpf-mascarado" value={cpf} onChange={(ev) => setCPF(ev.target.value)} inputComponent={MascaraCPF as any} />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField label="Senha" value={senha} onChange={(ev) => setSenha(ev.target.value)} type="password" />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="large" onClick={() => LoginService.logar(cpf, senha)}>
                            Entrar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
};

function App() {
    const [usuario, setUsuario] = useState<Usuario>();
    useEffect(() => LoginService.registrarCallBackLogin(setUsuario), []);

    const rotas = (
        <Switch>
            <Route exact path="/">
                <TelaResumo />
            </Route>
            <Route exact path="/recebimento-consumo">
                <TelaRecebimentoConsumo />
            </Route>
            <Route exact path="/consumo/cadastros">
                <TelaGerenciaCadastroConsumo />
            </Route>
            <Route exact path="/termo-de-entrega">
                <TelaTermosDeEntrega />
            </Route>
            <Route exact path="/termo-de-entrega/:id">
                <TelaDetalhesTermoDeEntrega />
            </Route>
            {/*<Route exact path="/permanente/cadastrar-novo">
                <TelaCadastroPermanente />
    </Route>*/}
            <Route exact path="/contabilidade">
                <TelaContabilidade />
            </Route>
            <Route exact path="/entregas">
                <TelaEntregas />
            </Route>
            <Route exact path="/permanente/recebimento">
                <TelaRecebimentoPermanente />
            </Route>
            {/*<Route exact path="/permanente/:id">
                <TelaDetalhesMaterialPermanente />
    </Route>*/}
        </Switch>
    );

    if (!usuario) {
        return <TelaLogin setUsuario={setUsuario} />
    } else {
        return (
            <Router>
                <BarraNavegacao />
                <Container className="App">
                    <LocalizationProvider
                        locale={ptBR}
                        dateAdapter={AdapterDateFns}>
                        {rotas}
                    </LocalizationProvider>
                </Container>
            </Router>
        );
    }

}

export default App;
