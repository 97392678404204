import axios from "axios";
import { Unidade } from "./entidades/unidades";
//import { useState } from "react";
import { useObservable } from "react-use";
import { BehaviorSubject } from "rxjs";
import { Usuario } from "./entidades/usuario";

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/*
export function useUsuarioLogado(): Usuario | undefined {
    let usrStr = localStorage.getItem('usrLogado');
    let [usuario, setUsuario] = useState<Usuario>(usrStr && JSON.parse(usrStr));
    return usuario;
}
*/

interface Privilegio {
    appId: number;
    appNome: string;
    obmId: Unidade['id'];
    privId: number;
    privNome: string;
}

export class ClasseLoginService {
    usr?: Usuario;
    permissoes: Privilegio[] = [];

    constructor() {
        let token = localStorage.getItem('token');
        this.logarPorToken(token || undefined);
    }
    logouUsr(u: Usuario) {
        usuario_logado_subject.next(u)
        this.usr = u;
        for (let callback of this.callbacksLogin) {
            callback(u);
        }
        this.carregarPermissoes(u).then(ps => this.permissoes = ps || [])
    }

    async carregarPermissoes(usr: Usuario) {
        try {
            let response = await axios.get<Privilegio[]>("/api/autenticacao/permissoes-do-usuario/" + usr.usrId);
            permissoes_subject.next(response.data)
            return response.data;
        } catch (err) {
            console.error(err);
            return []
        }
    }

    async logarPorToken(token?: string) {
        if (!token || token === '') {
            return;
        }
        try {
            this.usr = (await axios.post("/api/autenticacao/usuario-do-token", { token })).data;
            if (this.usr) {
                this.logouUsr(this.usr);
            }
        } catch (e) {
            localStorage.removeItem('token')
        }
    }

    async logar(cpf: string, senha: string) {
        const token = (await axios.post("/api/autenticacao", { cpf, senha })).data;
        localStorage.setItem('token', token);
        this.logarPorToken(token);
    }

    registrarCallBackLogin(call: (usuario: Usuario) => void) {
        this.callbacksLogin.push(call);
        if (this.usr) {
            call(this.usr);
        }
    }

    temPermissao(nome: string): boolean {
        return this.permissoes.some(p => p.privNome === nome);
    }

    /**
     * 
     * @param nome do privilégio
     * @returns Lista de ids das unidades
     */
    unidadesComPermissao(nome: string): Unidade['id'][] {
        return this.permissoes.filter(p => p.privNome === nome).map(p => p.obmId);
    }

    private callbacksLogin: ((usuario: Usuario) => void)[] = [];

    logout() {
        usuario_logado_subject.next(undefined)
        permissoes_subject.next([])
        this.usr = undefined;
        localStorage.removeItem('token');
    }
}

export const LoginService = new ClasseLoginService();

export const useUsuarioLogado = () => useObservable(usuario_logado_subject)
export const usePermissoesUsrLogado = () => useObservable(permissoes_subject)

const usuario_logado_subject = new BehaviorSubject<Usuario | undefined>(undefined);
const permissoes_subject = new BehaviorSubject<Privilegio[]>([]);
