import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Link as MaterialLink } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { ExibePasta } from "../../componentes/exibicao-entidades";
import { useTermoDeEntega } from "../../entidades/materiais/termo-de-entrega";
import { formataData } from "../../util/ferramentas";

function ExibeOrigem(props: {termo: number}) {
    const termo_id = props.termo
    const termo = useTermoDeEntega(termo_id)
    return <>
        <p>{termo}</p>
    </>
}

export function TelaContabilidade() {
    const [items, setItems] = useState([] as any[]);

    useEffect(() => {
        axios.get('/api/contabilidade').then(r => {
            setItems(_.sortBy(r.data, 'data'))
        });
    }, []);

    return (
        <Paper>
            <h3>Entrada / Saída de Material</h3>
            <Table size="small">
                <TableHead sx={{
                    "& th": {
                        textAlign: "center", fontWeight: "bold"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Data
                        </TableCell>
                        <TableCell colSpan={2}>
                            Material
                        </TableCell>
                        <TableCell>
                            Qnt
                        </TableCell>
                        <TableCell>
                            Tipo
                        </TableCell>
                        <TableCell>
                            Recebimento / Termo de Entrega
                        </TableCell>
                        <TableCell>
                            Origem
                        </TableCell>
                        <TableCell>
                            Destino
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.slice(500, 700).map(item =>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}>
                                {formataData(item.data)}
                            </TableCell>
                            <TableCell align="right">
                                <b>{item.nome}</b>
                            </TableCell>
                            <TableCell>
                                {item.especificacao}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center', color: item.qnt < 0 ? 'red' : 'green' }}>
                                {item.qnt}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                {item.tipo}
                            </TableCell>
                            <TableCell>
                                {!!item.termo?
                                    (<MaterialLink target="_blank" href={"/termo-de-entrega/"+item.termo}>
                                        Termo nº{item.termo}
                                    </MaterialLink>):
                                    (<></>)
                                }
                            </TableCell>
                            <TableCell>
                                <ExibeOrigem termo={item.termo} />
                            </TableCell>
                            <TableCell>
                                <ExibePasta id={item.pasta}/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    )
}
