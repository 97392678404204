import { Button, Dialog, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { CadastroMaterialConsumo, CadastroMaterialConsumoService } from "../../entidades/materiais/cadastro-consumo";
import { handle_erro } from "../../util/ferramentas";

function SelecaoMaterial(props: {
    cadastros: CadastroMaterialConsumo[],
    selecionado: number,
    set_selecionado: (s: number) => void
}) {
    const {
        cadastros,
        selecionado,
        set_selecionado,
    } = props;
    const [filtro, set_filtro] = useState("");
    const cads = cadastros.filter(cad => cad.nome.toLowerCase().includes(filtro) || cad.id === selecionado)

    return <Paper>
        <input
            value={filtro}
            onChange={ev => set_filtro(ev.target.value)}
            placeholder="Filtro" />
        <table>
            <tbody>
                {
                    cads.map((cad, i) => <tr key={cad.id}>
                        <td><input
                            type="radio"
                            checked={selecionado === cad.id}
                            onChange={() => set_selecionado(cad.id)}
                        /></td>
                        <td className="alinha-direita">{cad.nome} - </td>
                        <td className="alinha-esquerda">{cad.especificacao}</td>
                    </tr>)
                }
            </tbody>
        </table>
    </Paper>
}

function BotaoMesclarMateriais(props: { habilitado: boolean, mesclar: () => Promise<void> }) {
    const [alerta_aberto, set_alerta_aberto] = useState(false)
    const mesclar = async () => {
        await props.mesclar()
        set_alerta_aberto(false)
    }
    return <>
        <Button
            variant="outlined"
            onClick={() => set_alerta_aberto(true)}
            disabled={props.habilitado}>
            Mesclar materiais
        </Button>
        <Dialog
            open={alerta_aberto}
            onClose={() => set_alerta_aberto(false)}>
            <Box padding={1}>
                <p>
                    Todos os documentos e tabelas onde o material da segunda coluna aparecem serão substituídos pelo material da primeira coluna.
                    Inclusive nas contabilidades de materiais, termos de entrega concluídos e recebimentos de materiais.
                </p>
                <p>Essa ação <b>não</b> pode ser revertida.</p>
                <p>Tem certeza que deseja mesclar?</p>
                <Button variant="outlined" onClick={() => set_alerta_aberto(false)}>Cancelar</Button>
                <Button variant="outlined" onClick={mesclar}>Mesclar</Button>
            </Box>
        </Dialog>
    </>
}

export function TelaGerenciaCadastroConsumo() {
    const [cadastros, set_cadastros] = useState<CadastroMaterialConsumo[]>([])
    const [id_selecionado_1, set_id_selecionado_1] = useState(0)
    const [id_selecionado_2, set_id_selecionado_2] = useState(0)

    const carrega_cadastros = () => {
        CadastroMaterialConsumoService
            .lista_todos()
            .then(set_cadastros)
    }

    const mesclar_materiais = async () => {
        const cadastro_1 = cadastros.filter(cad => cad.id === id_selecionado_1)[0]
        const cadastro_2 = cadastros.filter(cad => cad.id === id_selecionado_2)[0]
        CadastroMaterialConsumoService.mesclar(
            cadastro_1,
            cadastro_2,
        ).catch(e => handle_erro('Falha ao mesclar materiais. Tente novamente mais tarde.', e))
            .then(carrega_cadastros)
    }

    useEffect(carrega_cadastros, [])

    return <div>
        <br />

        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                <BotaoMesclarMateriais
                    habilitado={id_selecionado_1 === 0 || id_selecionado_2 === 0 || id_selecionado_1 === id_selecionado_2}
                    mesclar={mesclar_materiais} />
            </Grid>

            <Grid item xs={6}>
                <SelecaoMaterial
                    cadastros={cadastros}
                    selecionado={id_selecionado_1}
                    set_selecionado={set_id_selecionado_1} />
            </Grid>
            <Grid item xs={6}>
                <SelecaoMaterial
                    cadastros={cadastros}
                    selecionado={id_selecionado_2}
                    set_selecionado={set_id_selecionado_2} />
            </Grid>
        </Grid>
    </div>
}
