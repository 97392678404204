import { Add, Edit } from "@mui/icons-material";
import { Avatar, Box, Fab, Grid, Hidden, IconButton, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Paginados } from "../../componentes/paginacao";
import { SelecionaUnidade } from "../../componentes/seleciona-pasta";
import { TermoEntrega, TermoEntregaService } from "../../entidades/materiais/termo-de-entrega";
import { usePasta } from "../../entidades/pasta";
import { useUsuario } from "../../entidades/usuario";
import { formataData, srcFoto, useWindowSize } from "../../util/ferramentas";

export function TelaTermosDeEntrega() {
    const [, windowHeight] = useWindowSize();
    let [termos, setTermos] = useState<TermoEntrega[]>([]);
    
    let filtroRascunhos = useState<'rascunho' | 'aguardando' | 'liberado'>('aguardando');
    let [filtroTipoDestino, setFiltroTipoDestino] = useState(['obm', 'usr']);

    let [dialogoAberto, setDialogoAberto] = useState(false);
    let history = useHistory();

    const handleFechaDialogo = (pastaId?: string) => {
        setDialogoAberto(false);
        if (pastaId !== undefined) {
            TermoEntregaService.criar(pastaId).then(termo => {
                history.push("/termo-de-entrega/" + termo.id);
            });
        }
    }

    useEffect(() => {
        TermoEntregaService.buscaRascunhos().then(setTermos);
    }, []);

    termos = _.sortBy(termos, ['id'])

    // Filtro Rascunho
    termos = termos.filter(t => {
        switch (filtroRascunhos[0]) {
            case 'rascunho':
                return !t.dataRecebimento && !t.dataElaboracao;
            case 'aguardando':
                return !t.dataRecebimento && !!t.dataElaboracao;
            case 'liberado':
                return !!t.dataRecebimento;
        }
    })

    // Filtro por tipo de destino
    termos = termos.filter(t => {
        if (!!t.destino) {
            return filtroTipoDestino.includes('obm');
        } else {
            return filtroTipoDestino.includes('usr');
        }
    })

    return (
        <>
            <Paper>
                <h3>
                    Termos de Entrega
                </h3>
                <Box padding={1}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ToggleButtonGroup
                                size="small"
                                value={filtroRascunhos[0]}
                                exclusive
                                onChange={(ev, v) => filtroRascunhos[1](v)}
                            >
                                <ToggleButton value={'rascunho'}>Rascunhos</ToggleButton>
                                <ToggleButton value={'aguardando'}>Aguardando</ToggleButton>
                                <ToggleButton value={'liberado'}>Liberados</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        <Grid item>
                            <ToggleButtonGroup
                                size="small"
                                value={filtroTipoDestino}
                                onChange={(ev, vs) => setFiltroTipoDestino(vs)}
                            >
                                <ToggleButton value="obm">Para Unidade</ToggleButton>
                                <ToggleButton value="usr">Para Usuário</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paginados items={termos} componente={t => <ExibeTermo key={t.id} termo={t} />}></Paginados>
            <Fab color="primary" aria-label="add"
                style={{ position: "fixed", right: 16, top: windowHeight - 72 }}
                onClick={() => setDialogoAberto(true)}>
                <Add />
            </Fab>
            <SelecionaUnidade open={dialogoAberto} handleClose={handleFechaDialogo} />
        </>
    )
}

function ExibeTermo(props: { termo: TermoEntrega }) {
    let { termo } = props;
    let history = useHistory();
    let pastaOrigem = usePasta(termo.origem);
    let pastaDestino = usePasta(termo.destino);
    let respEntrega = useUsuario(termo.responsavelEntrega);
    let respRecebimento = useUsuario(termo.responsavelRecebimento);

    return (
        <Grid container marginTop={1.5} component={Paper} padding={0.5}>
            <Grid item container xs={12} justifyContent="space-between">
                <b style={{ display: 'flex', margin: 7 }}>Termo de entrega nº {termo.id}</b>
                <IconButton color="primary" size='small' style={{ display: 'flex', alignSelf: "end" }}
                    onClick={() => history.push("/termo-de-entrega/" + termo.id)}>
                    <Edit />
                </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box flexDirection="row" display="flex">
                    <Box>
                        <Avatar sx={{ width: 64, height: 80, marginRight: 0.5 }} variant="rounded" src={srcFoto(respEntrega.foto)} />
                    </Box>
                    <Box flexGrow={1}>
                        <div>
                            Origem: <strong>{pastaOrigem.nome}</strong>
                        </div>
                        <div>
                            <Hidden smDown>
                                <small>Envio ao Almox:</small>&nbsp;
                                </Hidden>
                            {formataData(termo.dataElaboracao)}
                        </div>
                        <div>
                            <Hidden smDown>
                                <small>Responsável entrega:</small>&nbsp;
                                </Hidden>
                            {respEntrega.hierarquia} {respEntrega.nome}
                        </div>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box flexDirection="row" display="flex">
                    <Box flexGrow={1}>
                        <div>
                            Destino: <strong>{pastaDestino.nome}</strong>
                        </div>
                        <div>
                            <Hidden smDown>
                                <small>Data entrega:</small>&nbsp;
                                </Hidden>
                            {formataData(termo.dataRecebimento)}
                        </div>
                        <div>
                            <Hidden smDown>
                                <small>Responsável recebimento:</small>&nbsp;
                                </Hidden>
                            {respRecebimento.hierarquia} {respRecebimento.nome}
                        </div>
                    </Box>
                    <Box>
                        <Avatar sx={{ width: 64, height: 80, marginLeft: 0.5 }} variant="rounded" src={srcFoto(respRecebimento.foto)} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} textAlign="start">
                {termo.obs &&
                <Box display="flex" alignItems="center" columnGap="10px">
                    <b>Obs.: </b>
                    <pre>{termo.obs}</pre>
                </Box>}
            </Grid>
        </Grid>
    )
}
