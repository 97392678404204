import { keyBy, uniq } from "lodash";
import { http } from "../util/ferramentas";

export interface Unidade {
    id: number;
    nome: string;
    pai: number;
    nomeExibicao: string;
}

class ClasseUnidadeService {
    unidades: { [id: number]: Unidade } = {};
    
    async carregaTodas() {
        this.unidades = keyBy(await http.get<Unidade[]>(`/api/unidade`).then(resp => resp.data), 'id');
    }
    
    async getAll() {
        return Object.values(this.unidades);
    }

    async get_lista(ids: number[]) {
        await this.carregaTodas()
        return uniq(ids).map(id => this.unidades[id])
    }

    async getById(id: number) {
        return this.unidades[id];
    }
}

export const UnidadeService = new ClasseUnidadeService();
