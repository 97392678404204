import { Delete, Print } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { Autocomplete, Box, Button, ButtonGroup, Divider, Drawer, Grid, Hidden, IconButton, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import * as DateFns from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { BuscaMaterialConsumoDisponivel } from "../../componentes/busca-material-consumo-disponivel";
import { CadastroMaterialConsumo, CadastroMaterialConsumoService, useCadastroMaterialConsumo } from "../../entidades/materiais/cadastro-consumo";
import { ItemConsumo, TermoEntrega, TermoEntregaService, useItemsConsumoDoTermo, useTermoDeEntega } from "../../entidades/materiais/termo-de-entrega";
import { Pasta, PastaService, usePasta } from "../../entidades/pasta";
import { useUsuario, Usuario, UsuarioService } from "../../entidades/usuario";
import { intercala, useDebounce } from "../../util/ferramentas";
import { DadosTermoDeEntrega, imprimeTermoDeEntrega } from "../../util/impressao";

//const QRCode = require('qrcode.react');

function ExibeItem(props: { item: ItemConsumo, index: number, rascunho: boolean, podeEditar: boolean }) {
    const { item, index, rascunho, podeEditar } = props;
    const [qnt, setQnt] = useState(item.quantidade);
    const material = useCadastroMaterialConsumo(item.material);

    const qntDebounced = useDebounce((quantidade: number) => {
        TermoEntregaService.saveItemConsumo({ ...item, quantidade });
    }, []);

    const mudaQnt = (ev: ChangeEvent<HTMLInputElement>) => {
        qntDebounced(ev.target.value)
        setQnt(+ev.target.value)
    }

    return (
        <TableRow style={{ fontWeight: 'bold' }}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell>{material.nome}</TableCell>
            <TableCell>{material.especificacao}</TableCell>
            <TableCell align="center">{material.codBarras}</TableCell>
            <TableCell>{material.marca}</TableCell>
            <TableCell style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200, whiteSpace: 'nowrap' }}
                title={material.descricao}>{material.descricao}</TableCell>
            <TableCell align="right">
                {(rascunho || podeEditar) ? <input value={qnt}
                    min="0"
                    style={{ border: "none", width: 70, margin: 0, textAlign: "end" }}
                    onChange={mudaQnt}
                    type="number" /> : qnt}
            </TableCell>
            {(rascunho || podeEditar) &&
                <TableCell className="sem-padding" align="center">
                    <IconButton color="primary" onClick={() => TermoEntregaService.removeItemConsumo(item.termo, item.material)}>
                        <Delete />
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    )
}

function ItemsConsumoLg(props: { items: ItemConsumo[], rascunho: boolean, podeEditar: boolean }) {
    const { items, rascunho, podeEditar } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell component="th" align="center">#</TableCell>
                    <TableCell component="th" align="center">Material</TableCell>
                    <TableCell component="th" align="center">Especificação</TableCell>
                    <TableCell component="th" align="center">Cód Barras</TableCell>
                    <TableCell component="th" align="center">Marca</TableCell>
                    <TableCell component="th" align="center">Descrição</TableCell>
                    <TableCell component="th" align="center">qnt</TableCell>
                    {rascunho && <TableCell align="center" />}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item, index) => <ExibeItem key={`${item.termo},${item.material}`} {...{ item, rascunho, index, podeEditar }} />)}
            </TableBody>
        </Table>
    )
}

function ExibeItemSm(props: { item: ItemConsumo }) {
    const { item } = props;
    const material = useCadastroMaterialConsumo(item.material);

    return (
        <Grid container direction="row">
            <Grid item xs={5}>
                <p><strong>{material.nome}</strong></p>
                <p>{material.especificacao}</p>
            </Grid>
            <Grid item xs={5}>
                <p>Cód. Barras: <b>{material.codBarras}</b></p>
                <p>Marca: <b>{material.marca}</b></p>
            </Grid>
            <Grid item xs={2}>
                <p>Qnt: <b>{item.quantidade}</b></p>
            </Grid>
        </Grid>
    )
}

function ItemsConsumoSm(props: { items: ItemConsumo[] }) {
    const { items } = props;
    return (
        <div>{intercala(items.map(item => <ExibeItemSm key={`${item.termo},${item.material}`} item={item} />), <Divider />)}</div>
    )
}

function ItemsConsumo(props: { id: number, rascunho: boolean, pastaId: string, podeEditar: boolean }) {
    const { id, rascunho, podeEditar } = props;
    const items = useItemsConsumoDoTermo(id);
    const [drawerAdicaoItemConsumo, setDrawerAdicaoItemConsumo] = useState(false);

    const handleAdicionouMaterial = (material?: CadastroMaterialConsumo) => {
        setDrawerAdicaoItemConsumo(false);
        if (material) {
            TermoEntregaService.adicionaItemConsumo(id, material.id);
        }
    }

    return (
        <>
            <Hidden lgUp><ItemsConsumoSm items={items} /></Hidden>
            <Hidden lgDown><ItemsConsumoLg {...{ items, rascunho, podeEditar }} /></Hidden>
            {rascunho &&
                <Box padding={1} textAlign="start">
                    <Button onClick={() => setDrawerAdicaoItemConsumo(true)} variant="contained" size="small">Adicionar</Button>
                    <Drawer anchor="right" open={drawerAdicaoItemConsumo} onClose={() => setDrawerAdicaoItemConsumo(false)}>
                        <BuscaMaterialConsumoDisponivel pasta="194u" adicionar={handleAdicionouMaterial} />
                    </Drawer>
                </Box>
            }
        </>
    )
}

function ItemsPermanentes() {
    return (
        <div>
            Em construção...
        </div>
    )
}

export function TelaDetalhesTermoDeEntrega() {
    const id = +useParams<{ id: string }>().id;
    const [termo, setTermo] = useTermoDeEntega(id);
    const origem = usePasta(termo.origem);
    const destino = usePasta(termo.destino);
    const history = useHistory();
    const [pastasUsrLogado, setPastasUsrLogado] = useState<Pasta[]>([]);

    const itemsConsumo = useItemsConsumoDoTermo(id);

    const [aba, setAba] = useState<string>('Consumo');
    const mudaAba = (event: React.ChangeEvent<{}>, novaAba: string) => {
        setAba(novaAba);
    };

    const termoElaborado = !!termo.dataElaboracao && !termo.dataRecebimento;
    const rascunho = !termo.dataRecebimento && !termoElaborado;
    const entregue = !!termo.dataRecebimento;
    const podeEditar = pastasUsrLogado.map(p => p.id).includes(termo.origem);

    const salvaTermo = (novo: TermoEntrega) => {
        if (JSON.stringify(novo) !== JSON.stringify(termo))
            toast.promise(TermoEntregaService.save(novo).then(setTermo), {
                pending: 'Salvando termo de entraga',
                success: 'Termo de entrega salvo com sucesso',
                error: 'Falha ao salvar o termo',
            })
    }

    const [opcoesPastas, setOpcoesPastas] = useState<Pasta[]>([]);
    useEffect(() => {
        PastaService.getAll().then(ps => ps.filter(p => p.tipo === 'orgao-detentor')).then(setOpcoesPastas)
        PastaService.pastasDoUsrLogado().then(setPastasUsrLogado);
    }, []);

    const handleChangePastaDestino = (destino: Pasta | null) => {
        const termoAtualizado = { ...termo, destino: destino?.id || '' };
        salvaTermo(termoAtualizado)
    }

    const [opcoesEfetivo, setOpcoesEfetivo] = useState<Usuario[]>([]);
    useEffect(() => { UsuarioService.getAll().then(setOpcoesEfetivo) }, []);

    const respEntrega = useUsuario(termo.responsavelEntrega);
    const handleChangeRespEntrega = (usr: Usuario | null) => {
        const termoAtualizado = { ...termo, responsavelEntrega: usr?.id };
        salvaTermo(termoAtualizado)
    }
    const respRecebimento = useUsuario(termo.responsavelRecebimento);
    const handleChangeRespRecebimento = (usr: Usuario | null) => {
        const termoAtualizado = { ...termo, responsavelRecebimento: usr?.id };
        salvaTermo(termoAtualizado)
    }
    const handleChangeObs = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        const valor = ev.target.value;
        if (valor === termo.obs) return;
        const termoAtualizado = { ...termo, obs: ev.target.value };
        salvaTermo(termoAtualizado)
    }
    const handleChangeData = (ev: string) => {
        if (ev === termo.dataElaboracao) return;
        const termoAtualizado = { ...termo, dataElaboracao: ev };
        salvaTermo(termoAtualizado)
    }

    const imprimeTermo = async () => {
        const itemsP = await Promise.all(itemsConsumo.map(async (item, i) => ({
            num: i + 1,
            material: await CadastroMaterialConsumoService.busca(item.material),
            qnt: item.quantidade
        })));
        const items = itemsP.map(item => ({
            ...item,
            nome: item.material.nome,
            especificacao: item.material.especificacao ? '- ' + item.material.especificacao : '',
        }))
        const dadosTermoDeEntrega: DadosTermoDeEntrega = {
            id: termo.id,
            origem: origem.nome,
            destino: destino.nome,
            responsavel: `${respEntrega.hierarquia} ${respEntrega.nome}`,
            recebedor: `${respRecebimento.hierarquia} ${respRecebimento.nome}`,
            data: DateFns.format(Date.parse(termo.dataRecebimento || ''), 'dd/MM/yyyy'),
            items: items,
            observacoes: termo.obs || ''
        }

        imprimeTermoDeEntrega(dadosTermoDeEntrega);
    }

    return (
        <>
            <h3>Termo de Entrega nº {id}</h3>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <Paper>
                        <Grid container padding={1} spacing={1} marginTop={0}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Origem"
                                    value={origem.nome || termo.origem}
                                    size="small"
                                    fullWidth
                                    disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={opcoesPastas}
                                    disabled={!rascunho || !podeEditar}
                                    value={destino}
                                    getOptionLabel={(p) => p.nome || ''}
                                    onChange={(ev, pasta) => handleChangePastaDestino(pasta)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Destino"
                                            size="small"
                                            fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={opcoesEfetivo}
                                    disabled={entregue}
                                    value={respEntrega}
                                    getOptionLabel={(usr) => usr.hierarquia + ' ' + usr.nome}
                                    onChange={(ev, usr) => handleChangeRespEntrega(usr)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Resp. Entrega"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={opcoesEfetivo}
                                    disabled={entregue}
                                    value={respRecebimento}
                                    getOptionLabel={(usr) => usr.hierarquia + ' ' + usr.nome}
                                    onChange={(ev, usr) => handleChangeRespRecebimento(usr)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Resp. Recebimento"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                        />}
                                />
                            </Grid>
                            <Hidden xsUp>
                                <Grid item xs={12}>
                                    <DatePicker
                                        label="Data"
                                        value={termo.dataElaboracao}
                                        onChange={(data) => handleChangeData(data || '')}
                                        renderInput={(params) => <TextField fullWidth variant="outlined" {...params} size="small" />}
                                    />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12}>
                                <TextField
                                    defaultValue={termo.obs}
                                    onBlur={(ev) => handleChangeObs(ev as any)}
                                    disabled={entregue}
                                    label="Observações"
                                    multiline
                                    fullWidth
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <br />
                    {/*<Hidden mdDown>
                        <Paper>
                            <Box padding={2}>
                                <QRCode value={`https://materiais.bombeiros.ms.gov.br/materiais/termo-de-entrega/${id}/scanner`} size={null} renderAs="svg" />
                            </Box>
                        </Paper>
                        <br />
                    </Hidden>*/}
                    <ButtonGroup variant="contained"
                        aria-label="outlined primary button group">
                        <Button onClick={() => { history.goBack() }}>Voltar</Button>
                        {termoElaborado &&
                            <Button onClick={async () => { await TermoEntregaService.concluir(termo); window.location.reload(); }} disabled={itemsConsumo.length === 0 || !podeEditar}>Concluir</Button>}
                        {rascunho &&
                            <Button onClick={async () => { await TermoEntregaService.enviarAlmox(termo); window.location.reload(); }} disabled={itemsConsumo.length === 0}>Enviar pedido</Button>}
                        {entregue && <Button onClick={() => { imprimeTermo() }} endIcon={<Print />}>Imprimir</Button>}
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Paper>
                        <Tabs value={aba} onChange={mudaAba} variant="fullWidth">
                            <Tab value="Consumo" label="Consumo" />
                            <Tab value="Permanente" label="Permanente" />
                        </Tabs>
                        <div hidden={aba !== 'Consumo'}>
                            <ItemsConsumo id={id} rascunho={rascunho} pastaId={termo.origem} podeEditar={podeEditar} />
                        </div>
                        <div hidden={aba !== 'Permanente'}>
                            <ItemsPermanentes />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
