import { CameraAlt } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { Autocomplete, Button, FilterOptionsState, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField } from "@mui/material";
import * as DateFns from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoginService } from "../../auth";
import { ScannerCodigoBarras } from "../../componentes/scanner-cod-barras";
import { CadastroMaterialConsumo, CadastroMaterialConsumoService } from "../../entidades/materiais/cadastro-consumo";
import { RecebimentoMaterialConsumo, RecebimentoMaterialConsumoService } from "../../entidades/materiais/recebimento-consumo";
import { Pasta, PastaService } from "../../entidades/pasta";
import { useUsuario, Usuario, UsuarioService } from "../../entidades/usuario";
import { semRepeticoes, temCamera } from "../../util/ferramentas";

function novoCadastroMaterialConsumo(): CadastroMaterialConsumo {
    return {
        id: 0,
        codBarras: '',
        nome: '',
        marca: '',
        especificacao: '',
        descricao: '',
        classe: '',
    }
}
function novoRecebimentoMaterialConsumo(): RecebimentoMaterialConsumo {
    return {
        material: 0,
        local: "194u",
        quantidade: 0,
        obs: "",
        fonte: "",
        notaEmpenho: "",
        responsavel: LoginService.usr?.id || 0,
        data: DateFns.format(new Date(), 'yyyy-MM-dd')
    }
}

export function TelaRecebimentoConsumo() {
    let [scanner, setScanner] = useState<JSX.Element>();
    const abrirScanner = () => {
        setScanner(<ScannerCodigoBarras fechar={fecharScanner} />);
    }
    const fecharScanner = (cod?: string) => {
        if (cod) {
            setCadastro(cad => ({ ...cad, codBarras: cod }))
        }
        setScanner(undefined);
    }

    let [cadastro, setCadastro] = useState<CadastroMaterialConsumo>(novoCadastroMaterialConsumo);
    const setCodBarras = (cod: string) =>
        CadastroMaterialConsumoService.buscaPorCodBarras(cod).then(cadastro => {
            if (cadastro) {
                setCadastro(cadastro)
                setMaterial(cadastro.id)
            } else {
                setCadastro(cad => {
                    let novoCad = { ...cad, codBarras: cod, id: 0 }
                    return novoCad;
                })
            }
        });
    const setNome = (nome: string) => setCadastro(cad => ({ ...cad, nome }));
    const setEspecificacao = (especificacao: string) => setCadastro(cad => ({ ...cad, especificacao }));
    const setMarca = (marca: string) => setCadastro(cad => ({ ...cad, marca }));
    const setDesc = (descricao: string) => setCadastro(cad => ({ ...cad, descricao }));

    let [recebimento, setRecebimento] = useState<RecebimentoMaterialConsumo>(novoRecebimentoMaterialConsumo);
    const setMaterial = (id: number) => setRecebimento(cad => ({ ...cad, material: id }));
    const setLocal = (local: string) => setRecebimento(cad => ({ ...cad, local }));
    const setData = (data: string) => setRecebimento(cad => ({ ...cad, data }));
    const setQnt = (ev: ChangeEvent<HTMLInputElement>) => setRecebimento(cad => ({ ...cad, quantidade: +ev.target.value }));
    const setObs = (ev: ChangeEvent<HTMLInputElement>) => setRecebimento(cad => ({ ...cad, obs: ev.target.value }));
    const setResp = (responsavel: number = 0) => setRecebimento(cad => ({ ...cad, responsavel }));
    const setFonte = (fonte: string) => setRecebimento(cad => ({ ...cad, fonte }));
    const setNE = (notaEmpenho: string) => setRecebimento(cad => ({ ...cad, notaEmpenho }));
    const responsavel = useUsuario(recebimento.responsavel);

    let [pastas, setPastas] = useState<Pasta[]>([]);
    useEffect(() => { PastaService.getAll().then(setPastas) }, []);
    let [usrs, setUsrs] = useState<Usuario[]>([]);
    useEffect(() => { UsuarioService.getAll().then(setUsrs) }, []);

    const salvar = () => {
        let promise = RecebimentoMaterialConsumoService.salvarCadastroERecebimento(cadastro, recebimento).then(rec => {
            setCadastro(novoCadastroMaterialConsumo);
            setRecebimento(novoRecebimentoMaterialConsumo);
        })
        toast.promise(
            promise,
            {
                pending: 'Salvando...',
                success: 'Recebimento salvo com sucesso',
                error: 'Falha ao salvar o recebimento'
            }
        )
    }

    const [opcoesNomes, setOpcoesNomes] = useState<string[]>([]);
    useEffect(() => { CadastroMaterialConsumoService.todosNomes().then(setOpcoesNomes) }, []);
    let filtroOpcoesNomes = (opts: string[], params: FilterOptionsState<string>) => {
        let filtro = params.inputValue.toLowerCase();
        let filtrados = semRepeticoes([...opts, params.inputValue]).filter(nome => nome.toLowerCase().indexOf(filtro) !== -1);
        return filtrados.sort();
    }
    const [opcoesEspecificacao, setOpcoesEspecificacao] = useState<string[]>([]);
    useEffect(() => { CadastroMaterialConsumoService.todasEspecificacoes(cadastro.nome).then(setOpcoesEspecificacao) }, [cadastro.nome]);
    let filtroOpcoesEspecificacao = (opts: string[], params: FilterOptionsState<string>) => {
        let filtro = params.inputValue.toLowerCase();
        let filtrados = semRepeticoes([...opts, params.inputValue]).filter(especificacao => especificacao.toLowerCase().indexOf(filtro) !== -1);
        return filtrados.sort();
    }
    const [opcoesMarcas, setOpcoesMarcas] = useState<string[]>([]);
    useEffect(() => { CadastroMaterialConsumoService.todasMarcas().then(setOpcoesMarcas) }, []);
    let filtroOpcoesMarcas = (opts: string[], params: FilterOptionsState<string>) => {
        let filtro = params.inputValue.toLowerCase();
        let filtrados = semRepeticoes([...opts, params.inputValue]).filter(marca => marca.toLowerCase().indexOf(filtro) !== -1);
        return filtrados;
    }

    return (
        <Paper style={{ padding: 10, marginTop: 20 }}>
            {scanner}
            <h3>Entrada de Material de Consumo</h3>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={1}>
                        <h4>Cadastro do Material</h4>
                        <Grid item>
                            <TextField label="Cód. Barras"
                                value={cadastro.codBarras}
                                fullWidth
                                onBlur={(ev) => setCodBarras(ev.target.value)}
                                onChange={(ev) => setCadastro(cad => ({ ...cad, codBarras: ev.target.value }))}
                                InputProps={temCamera() ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton color="primary" onClick={abrirScanner}>
                                                <CameraAlt />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                } : undefined} />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                disabled={cadastro.id !== 0}
                                options={opcoesNomes}
                                filterOptions={filtroOpcoesNomes}
                                onInputChange={(ev, value) => !!value && setNome(value)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Material"
                                        variant="outlined"
                                        fullWidth
                                        value={cadastro.nome} />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                disabled={cadastro.id !== 0}
                                options={opcoesEspecificacao}
                                filterOptions={filtroOpcoesEspecificacao}
                                onInputChange={(ev, value) => !!value && setEspecificacao(value)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Especificação"
                                        fullWidth
                                        value={cadastro.especificacao}
                                        variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                disabled={cadastro.id !== 0}
                                options={opcoesMarcas}
                                filterOptions={filtroOpcoesMarcas}
                                onInputChange={(ev, value) => !!value && setMarca(value)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Marca"
                                        fullWidth
                                        value={cadastro.marca}
                                        variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <TextField label="Descrição"
                                multiline rows={4} fullWidth
                                disabled={cadastro.id !== 0}
                                value={cadastro.descricao}
                                onChange={(ev) => setDesc(ev.target.value)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={1}>
                        <h4>Entrada</h4>
                        <Grid item>
                            <DatePicker
                                label="Data"
                                value={recebimento.data}
                                onChange={(newValue) => setData(newValue || '')}
                                renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                            />
                        </Grid>
                        <Grid item>
                            <TextField label="Unidade" fullWidth
                                select
                                value={recebimento.local}
                                onChange={(ev) => setLocal(ev.target.value)}>
                                {
                                    pastas.filter(p => p.tipo === 'orgao-detentor').map(pasta => (
                                        <MenuItem key={pasta.id} value={pasta.id}>
                                            {pasta.nome}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={usrs}
                                value={responsavel}
                                getOptionLabel={(usr) => usr.hierarquia + ' ' + usr.nome}
                                onChange={(ev, usr) => setResp(usr?.id)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Responsável"
                                        fullWidth
                                        variant="outlined"
                                    />}
                            />
                        </Grid>
                        <Grid item>
                            <TextField label="Quantidade" fullWidth
                                type="number"
                                value={recebimento.quantidade}
                                onChange={setQnt} />
                        </Grid>
                        <Grid item>
                            <TextField label="Fonte" fullWidth
                                value={recebimento.fonte}
                                onChange={(ev) => setFonte(ev.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="Nota de Empenho" fullWidth
                                value={recebimento.notaEmpenho}
                                onChange={(ev) => setNE(ev.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="Observações" multiline rows={4} fullWidth
                                value={recebimento.obs}
                                onChange={setObs} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <br />
                <Button variant="contained" onClick={salvar}>
                    Salvar
                </Button>
            </Grid>
        </Paper>
    )
}
