import { Download } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { criaPlanilha } from "../../util/excel";
import { formataData } from "../../util/ferramentas";

function editar_data(data: string): string | null {
    const [dd, mm, aaaa] = data.split('/')
    if (dd === undefined) return null;
    if (mm === undefined) return null;
    if (aaaa === undefined) return null;
    return `${aaaa}-${mm}-${dd}`
}

export function TelaEntregas() {
    const [items, setItems] = useState([] as any[])
    const [material, set_material] = useState<string>()
    const [destino, set_destino] = useState<string>()
    const [intervalo_de, set_intervalo_de] = useState<string>("")
    const [intervalo_ate, set_intervalo_ate] = useState<string>("")

    const materiais: string[] = _.uniq(items.map(item => item.material).sort())
    const destinos: string[] = _.uniq(items.map(item => item.destino).sort())
    const intervalo_editado_de = editar_data(intervalo_de)
    const intervalo_editado_ate = editar_data(intervalo_ate)

    useEffect(() => {
        axios.get('/api/contabilidade/entregas').then(r => {
            setItems(_.sortBy(r.data, 'data'))
        });
    }, []);

    let items_filtrados = items.filter(item => !material || item.material === material)
    items_filtrados = items_filtrados.filter(item => !destino || item.destino === destino)
    items_filtrados = items_filtrados.filter(item => !intervalo_editado_de || item.data >= intervalo_editado_de)
    items_filtrados = items_filtrados.filter(item => !intervalo_editado_ate || item.data <= intervalo_editado_ate)

    const [pagina, set_pagina] = useState(0);
    const items_paginados = [[], ..._.chunk(items_filtrados, 20)];
    if (pagina === 0 && items_paginados.length > 1) {
        set_pagina(1)
    }
    if (pagina > items_paginados.length) {
        set_pagina(1);
    }

    const criaPlanilhaEntregas = () => {
        const item2row = (item: any) => [
            item.material,
            item.quantidade,
            item.data,
            item.origem,
            item.destino,
        ]
        const blob = criaPlanilha('Entrega Material')
            .addPage('entregas', [
                ['Nome Material', 'Quantidade', 'Data', 'Origem', 'Destino'],
                ...items_filtrados.map(item2row)
            ])
            .buildBlob();

        saveAs(blob, `materiais entreques${!!destino? ` (${destino})`: ''}.xlsx`);
    }

    return (
        <Paper>
            <h3>
                Entrada / Saída de Material
                <IconButton color="primary" onClick={criaPlanilhaEntregas}>
                    <Download />
                </IconButton>
            </h3>
            <Table size="small">
                <TableHead sx={{
                    "& th": {
                        textAlign: "center", fontWeight: "bold"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Material
                            <br />
                            <Select
                                size="small"
                                value={material}
                                onChange={ev => set_material(ev.target.value)}>
                                {materiais.map(p => <MenuItem value={p}>{p}</MenuItem>)}
                            </Select>
                        </TableCell>
                        <TableCell>
                            Qnt
                        </TableCell>
                        <TableCell>
                            Data
                            <br />
                            <TextField
                                error={!!intervalo_de && !intervalo_editado_de}
                                sx={{ width: "120px" }}
                                placeholder="dd/mm/aaaa"
                                size="small"
                                label="de"
                                value={intervalo_de}
                                onChange={ev => set_intervalo_de(ev.target.value)} />
                            <TextField
                                error={!!intervalo_ate && !intervalo_editado_ate}
                                sx={{ width: "120px" }}
                                placeholder="dd/mm/aaaa"
                                size="small"
                                label="até"
                                value={intervalo_ate}
                                onChange={ev => set_intervalo_ate(ev.target.value)} />
                        </TableCell>
                        <TableCell>
                            Origem
                        </TableCell>
                        <TableCell>
                            Destino
                            <br />
                            <Select
                                size="small"
                                value={destino}
                                onChange={ev => set_destino(ev.target.value)}>
                                {destinos.map(p => <MenuItem value={p}>{p}</MenuItem>)}
                            </Select>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(items_paginados[pagina] || []).map(item =>
                        <TableRow>
                            <TableCell align="left">
                                <b>{item.material}</b>
                            </TableCell>
                            <TableCell align="right">
                                {item.quantidade}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                {formataData(item.data)}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                {item.origem}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                                {item.destino}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Grid container justifyContent="center" padding={2}>
                <Pagination count={items_paginados.length - 1} variant="outlined" shape="rounded" onChange={(v, p) => p && set_pagina(p)} page={pagina} />
            </Grid>
        </Paper>
    )
}
