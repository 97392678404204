import _ from "lodash";
import { useEffect, useState } from "react";
import { LoginService } from "../auth";
import { http } from "../util/ferramentas";

/*export function flatPastas(us: Pasta[] | Pasta): Pasta[] {
    if (!Array.isArray(us)) {
        us = [us];
    }
    return [...us, ...us.map(u => flatPastas(u.filhos)).flat()];
}*/

export interface Pasta {
    id: string;
    nome: string;
    tipo: 'orgao-detentor' | 'pasta' | 'detentor-agente' | string;
    unidade: number;
    ord: number;
    nomeCompleto: string;
    ancestrais: string[];
    filhos: string[];
}

class ClassePastaService {
    pastas: { [id: string]: Pasta } = {};
    inicializado: Promise<void> = this.carregaTodas();

    async carregaTodas() {
        let tds = await http.get<Pasta[]>(`/api/pasta`).then(resp => resp.data);
        for (let pasta of tds) {
            this.pastas[pasta.id] = pasta;
        }
    }

    async getAll() {
        await this.inicializado;
        return Object.values(this.pastas);
    }

    async getById(id: string) {
        await this.inicializado;
        return this.pastas[id];
    }

    /*async agentesDaPasta(pasta: Pasta['id']) {
        let agentes = await http.get<Usuario[]>(`/api/pasta/agentes`).then(resp => resp.data);
    }*/

    async pastasDoUsrLogado() {
        await this.inicializado;
        const unidades = LoginService.unidadesComPermissao('Agente Almox');
        return Object.values(this.pastas).filter(p => unidades.includes(p.unidade))
    }

    async todasHierarquicas() {
        await this.inicializado;
        type PastaNode = Omit<Pasta, 'filhos'> & {pai?: Pasta, filhos: PastaNode[]};
        const pastas: { [id: string]: PastaNode } = _.mapValues(this.pastas, p => ({...p, filhos: [], pai: undefined}));
        let arvores: PastaNode[] = [];

        _.mapValues(pastas, p => {
            let length = p.ancestrais.length;
            const pai = length > 1? p.ancestrais[length-2]: undefined;

            if (!pai) {
                arvores.push(p);
            } else {
                pastas[pai].filhos.push(p);
            }
        })

        function ordena(ps: PastaNode[]) {
            ps.map(p => p.filhos = ordena(p.filhos));
            return _.sortBy(ps, ['ord']);
        }

        arvores = ordena(arvores);

        return arvores;
    }
}

export const PastaService = new ClassePastaService();

export function usePasta(id: string): Pasta {
    let [pasta, setPasta] = useState<Pasta>({ } as any);
    useEffect(() => {
        PastaService.inicializado.then(() => {
            PastaService.getById(id).then(p => p && setPasta(p));
        })
    }, [id])

    return pasta;
}
