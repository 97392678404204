import { Grid, Pagination } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

type PaginadosProps<T> = {
    items: T[],
    componente: (item: T) => JSX.Element,
    itemsPorPagina?: number
};

export function Paginados<T>(props: PaginadosProps<T>) {
    const [pagina, setPagina] = useState(1);
    let { items, itemsPorPagina, componente } = props;
    itemsPorPagina = itemsPorPagina || 10;
    const itemsPaginados = _.chunk(items, itemsPorPagina)
    const itemsDaPagina = itemsPaginados[pagina-1] || [];

    return (
        <>
            { itemsDaPagina.map(componente)}
            <Grid container justifyContent="center" padding={2}>
                <Pagination
                    page={pagina}
                    count={itemsPaginados.length}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, p) => p && setPagina(p)} />
            </Grid>
        </>
    )
}
