import { useEffect, useState } from 'react';
import { http, semRepeticoes } from '../../util/ferramentas';

export interface CadastroMaterialConsumo {
    id: number
    codBarras?: string
    nome: string
    especificacao: string
    marca?: string
    descricao?: string
    classe: string
}

function novoCadastro(): CadastroMaterialConsumo {
    return {
        id: 0,
        codBarras: '',
        nome: '',
        especificacao: '',
        marca: '',
        descricao: '',
        classe: '',
    }
}

class ClasseCadastroMaterialConsumoService {
    private todos: { [id: number]: CadastroMaterialConsumo } = {};
    private porCodBarra: { [cod: string]: CadastroMaterialConsumo } = {};
    private inicializado: Promise<any> = this.carregaTodos();

    async lista_todos() {
        await this.inicializado;
        return Object.values(this.todos)
    }

    async carregaTodos() {
        let tds = await http.get<CadastroMaterialConsumo[]>(`/api/cadastro-material-consumo`).then(resp => resp.data);
        for (let cad of tds) {
            this.todos[cad.id] = cad;
            if (cad.codBarras) {
                this.porCodBarra[cad.codBarras] = cad;
            }
        }
    }

    async salvar(cadastro: CadastroMaterialConsumo) {
        return await http.post('/api/cadastro-material-consumo', cadastro).then(resp => resp.data);
    }

    /**
     * Essa função mescla dois cadastros em um só.
     * Ela foi concebida para tratar pequenos erros de cadastro onde o usuário cometeu algum erro de digitação ou mudou a nomenclatura de um material,
     * resultando em dois ou mais cadastros que deveriam representar o mesmo material.
     * 
     * Todos os locais onde o material_2 aparece serão substituídos pelo material_1. Inclusive nas contabilidades de materiais, termos de entrega concluídos
     * e recebimentos de materiais.
     * 
     * @param cadastro_1 O cadastro com a nomenclatura que vai permanecer para os dois materiais.
     * @param cadastro_2 O cadastro a ser substituído (vai ter que nomenclatura corrigida em todo o sistema).
     */
    async mesclar(cadastro_1: CadastroMaterialConsumo, cadastro_2: CadastroMaterialConsumo) {
        return await http.post('/api/cadastro-material-consumo/mesclar', { cadastro_1, cadastro_2 });
    }

    async busca(id: number) {
        await this.inicializado;
        if (!this.todos[id]) {
            this.todos[id] = await http.get(`/api/cadastro-material-consumo/${id}`).then(resp => resp.data)
            return this.todos[id];
        }
        return this.todos[id];
    }

    async buscaEmLote(ids: number[]) {
        await this.inicializado;
        const idsNaoEncontrados = semRepeticoes(ids.filter(id => !this.todos[id]).sort());
        Promise.all(idsNaoEncontrados.map(id => http.get(`/api/cadastro-material-consumo/${id}`).then(resp => this.todos[id] = resp.data).catch(() => this.todos[id] = novoCadastro())))

        const materiais: { [id: number]: CadastroMaterialConsumo } = {};
        for (let id of ids) {
            materiais[id] = this.todos[id];
        }

        return materiais;
    }

    async buscaPorCodBarras(cod: string) {
        await this.inicializado;
        return this.porCodBarra[cod];
    }

    async todosNomes() {
        await this.inicializado;
        return Object.values(this.todos).map(m => m.nome).filter(nome => !!nome);
    }
    async todasEspecificacoes(nome: string) {
        await this.inicializado;
        return Object.values(this.todos).filter(cad => cad.nome.toLowerCase() === nome.toLowerCase()).map(m => m.especificacao).filter(especificacao => !!especificacao);
    }
    async todasMarcas() {
        await this.inicializado;
        return Object.values(this.todos).map(m => m.marca).filter(marca => !!marca) as string[];
    }
}

export const CadastroMaterialConsumoService = new ClasseCadastroMaterialConsumoService();

export function useCadastroMaterialConsumo(id: number): CadastroMaterialConsumo {
    const [cadastro, setCadastro] = useState(novoCadastro());
    useEffect(() => {
        CadastroMaterialConsumoService.busca(id).then(setCadastro);
    }, [id])

    return cadastro;
}
