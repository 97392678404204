import * as XLSX from 'xlsx';

class PlanilhaBuilder {
    private wb:  XLSX.WorkBook;

    constructor(titulo: string, assunto: string = '') {
        this.wb = XLSX.utils.book_new();
        this.wb.Props = {
            Title: titulo,
            Subject: assunto,
            Author: "Sistema de Materiais - DTel - CBMMS",
            CreatedDate: new Date()
        };
    }

    public addPage(nome: string, celulas: any[][]) {
        this.wb.SheetNames.push(nome);
        this.wb.Sheets[nome] = XLSX.utils.aoa_to_sheet(celulas);

        return this;
    }

    public buildBlob() {
        const wbOut = XLSX.write(this.wb, { bookType: 'xlsx', type: 'array',  });
        return new Blob([wbOut]);
    }

    /*public build(nomeArquivo: string = (this.wb.Props?.Title || 'planilha')+'.xlsx') {
        return 
    }*/
}

export function criaPlanilha(titulo: string, assunto: string = '') {
    return new PlanilhaBuilder(titulo, assunto);
    //saveAs(new Blob([wbout], { type: 'application/octet-stream' }), titulo+'.xlsx')
}
