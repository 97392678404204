import Quagga from "@ericblade/quagga2";
import { Button } from "@mui/material";
import { useLayoutEffect, useRef } from "react";

export function ScannerCodigoBarras(props: { fechar: (cod?: string) => void }) {
    let ref = useRef<HTMLDivElement>(null);
    let height = window.screen.availHeight;
    let width = window.screen.availWidth;

    let styleExterno: React.CSSProperties = { position: "fixed", top: 0, right: 0, width, height, backgroundColor: "black", zIndex: 10000 };
    let styleInterno: React.CSSProperties = {
        backgroundColor: "white",
        //transform: 'rotate(90deg)',
        //height: width, width: height,
        //transformOrigin: `${width / 2}px ${width / 2}px`
    };

    useLayoutEffect(() => {
        Quagga.init({
            inputStream: {
                type: "LiveStream",
                target: ref.current as any,
            },
            /*locator: {
                halfSample: true,
            },*/
            decoder: {
                readers: ["code_128_reader", "ean_reader"],
                debug: { drawBoundingBox: true }
            }
        }, err => {
            if (err) {
                console.log(err);
            }
            Quagga.start();
            Quagga.onDetected((cod) => {
                //alert(JSON.stringify(cod))
                if (cod.codeResult.code) {
                    props.fechar(cod.codeResult.code);
                }
            });
            return () => {
                Quagga.stop();
            }
        });
    });

    return (
        <div style={styleExterno}>
            <div style={styleInterno} ref={ref}>
                <Button onClick={() => props.fechar()}>Fechar</Button>
            </div>
        </div>
    )
}