import { Box, Checkbox, Drawer, Grid, Hidden, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { Download, FilterAlt } from "@mui/icons-material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConsumoEmEstoqueService } from "../../entidades/materiais/estoque-consumo";
import { ItemResumo } from "../../entidades/materiais/item-resumo";
import { normalizado, semRepeticoes } from "../../util/ferramentas";
import styles from './resumo.module.css';
import _ from "lodash";
import { criaPlanilha } from "../../util/excel";
import { Pasta, PastaService } from "../../entidades/pasta";
import { LoginService } from "../../auth";

const ContextoTelaResumo = createContext<{
    filtroPastas: { [id: string]: boolean },
    setFiltroPastas: React.Dispatch<React.SetStateAction<{ [id: string]: boolean }>>,
    filtroMateriais: string,
    setFiltroMateriais: React.Dispatch<React.SetStateAction<string>>,
}>({
    filtroPastas: {},
    setFiltroPastas: () => { },
    filtroMateriais: '',
    setFiltroMateriais: () => { },
});

type PastaNode = Omit<Pasta, 'filhos'> & { pai?: PastaNode, filhos: PastaNode[] };

function filhosDaPastaNoFiltro(raiz: PastaNode, filtro: { [id: string]: boolean }): 'todos' | 'nenhum' | 'alguns' {
    if (raiz.filhos.length === 0) {
        if (filtro[raiz.id]) {
            return 'todos';
        } else {
            return 'nenhum';
        }
    }

    const filhos = raiz.filhos.map(f => filhosDaPastaNoFiltro(f, filtro));
    if (filhos.every(s => s === 'todos')) {
        return 'todos';
    } else if (filhos.every(s => s === 'nenhum')) {
        return 'nenhum';
    } else {
        return 'alguns';
    }
}

function ExibePastaRec(props: { pasta: PastaNode, handleChange: (pasta: PastaNode) => void }) {
    const [aberto, setAberto] = useState(false);
    const contexto = useContext(ContextoTelaResumo);
    const filtroPastas = contexto.filtroPastas;

    const { pasta, handleChange } = props;
    const filhos = pasta.filhos;

    return <Box>
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
                <IconButton onClick={() => setAberto(!aberto)} disabled={filhos.length === 0} size="small">
                    {aberto ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                </IconButton>
                {pasta.nome}
            </Box>
            <Checkbox size="small"
                checked={!!filtroPastas[pasta.id]}
                indeterminate={filhosDaPastaNoFiltro(pasta, filtroPastas) === 'alguns'}
                onChange={() => handleChange(pasta)}
            />
        </Box>
        {aberto &&
            <Box marginLeft={2}>
                {filhos.map(p => <ExibePastaRec key={p.id} pasta={p} handleChange={handleChange} />)}
            </Box>
        }
    </Box>
}

function FiltroPasta() {
    const contexto = useContext(ContextoTelaResumo);
    const [pastas, setPastas] = useState<any[]>([]);
    useEffect(() => { PastaService.todasHierarquicas().then(setPastas) }, [])

    const handleChange = (pasta: PastaNode) => {
        contexto.setFiltroPastas((filtros) => {
            const novoFiltro = { ...filtros };
            const novoValor = !novoFiltro[pasta.id];
            function setaValor(p: PastaNode) {
                novoFiltro[p.id] = novoValor;
                p.filhos.map(f => setaValor(f))
            }
            setaValor(pasta);
            return novoFiltro;
        })
    }

    const marcaTodos = (valor: boolean) => {
        contexto.setFiltroPastas((filtros) => {
            const novoFiltro = { ...filtros };
            function setaValor(p: PastaNode) {
                novoFiltro[p.id] = valor;
                p.filhos.map(f => setaValor(f))
            }
            for (const pasta of pastas) {
                setaValor(pasta);
            }
            return novoFiltro;
        })
    }
    const raizVirtual = {
        filhos: pastas
    } as PastaNode;

    return (
        <Box ml={2}>
            <h3>
                Pastas
            </h3>
            <Box display="flex" justifyContent="space-between" marginBottom={1}>
                <Box display="flex" alignItems="center">Marcar todos</Box>
                <Checkbox
                    size="small"
                    checked={filhosDaPastaNoFiltro(raizVirtual, contexto.filtroPastas) === 'todos'}
                    indeterminate={filhosDaPastaNoFiltro(raizVirtual, contexto.filtroPastas) === 'alguns'}
                    onClick={() => marcaTodos(filhosDaPastaNoFiltro(raizVirtual, contexto.filtroPastas) !== 'todos')}
                />
            </Box>
            {pastas.map(p => <ExibePastaRec key={p.id} pasta={p} handleChange={handleChange} />)}
        </Box>
    )
}

function ExibeTelaResumo(props: { items: ItemResumo[], handleChangeNecessidadeDoItem: (index: number, qnt: number) => void }) {
    const [abertoFiltroPasta, setAbertoFiltroPasta] = useState(false);
    const contexto = useContext(ContextoTelaResumo);
    const { items } = props;

    const itemsFiltrados = items.filter(item => contexto.filtroPastas[item.localId])
        .filter(item => normalizado(item.materialNome + item.materialEspecificacao)
            .includes(normalizado(contexto.filtroMateriais)));

    const [pagina, setPagina] = useState(0);
    const itemsPaginados = [[], ..._.chunk(itemsFiltrados, 20)];
    if (pagina === 0 && itemsPaginados.length > 1) {
        setPagina(1)
    }
    if (pagina > itemsPaginados.length) {
        setPagina(1);
    }

    const handleChangeFiltroMateriais: React.ChangeEventHandler<HTMLInputElement> = (ev) => contexto.setFiltroMateriais(ev.target.value);
    const handleChangeNecessidade: (indice: number, item: ItemResumo) => React.ChangeEventHandler<HTMLInputElement> = () => ((ev) => {
        //const qnt = +ev.target.value;
    });

    const criaPlanilhaResumo = () => {
        const item2row = (item: ItemResumo) => [
            item.materialNome,
            item.materialEspecificacao,
            item.localNome,
            item.consumo,
            item.otimo,
            item.bom,
            item.regular,
            item.inservivel,
            item.total,
            item.necessidade,
        ]
        const blob = criaPlanilha('Resumo Materiais')
            .addPage('materiais', [
                ['Nome Material', 'Especificação Material', 'Local', 'Consumo', 'Ótimo', 'Bom', 'Regular', 'Inservível', 'Total', 'Necessidade'],
                ...itemsFiltrados.map(item2row)
            ])
            .buildBlob();

        saveAs(blob, 'resumo materiais.xlsx');
    }

    return (
        <Paper>
            <h3>
                Resumo
                <IconButton color="primary" onClick={criaPlanilhaResumo}>
                    <Download />
                </IconButton>
            </h3>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box><b>Material</b></Box>
                                <TextField id="outlined-basic" label="Filtro" variant="outlined" size="small"
                                    onChange={handleChangeFiltroMateriais}
                                    value={contexto.filtroMateriais} />
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <b>Local</b>
                            <Tooltip title="Filtrar">
                                <IconButton color="primary" component="span" size="small"
                                    onClick={() => setAbertoFiltroPasta(true)}>
                                    <FilterAlt />
                                </IconButton>
                            </Tooltip>
                            <Drawer
                                anchor='right'
                                open={abertoFiltroPasta}
                                onClose={() => setAbertoFiltroPasta(false)}
                            >
                                <FiltroPasta />
                            </Drawer>
                        </TableCell>
                        <Hidden mdDown>
                            <TableCell align="center" className="sem-padding"><b>C</b><small>onsumo</small></TableCell>
                            <TableCell align="center" className="sem-padding"><b>Ó</b><small>timo</small></TableCell>
                            <TableCell align="center" className="sem-padding"><b>B</b><small>om</small></TableCell>
                            <TableCell align="center" className="sem-padding"><b>R</b><small>egular</small></TableCell>
                            <TableCell align="center" className="sem-padding"><b>I</b><small>nservível</small></TableCell>
                        </Hidden>
                        <TableCell align="center" className="sem-padding"><b>T</b><small>otal</small></TableCell>
                        <TableCell align="center" className="sem-padding" style={{ display: 'none' }}><b>N</b><small style={{ textOverflow: "ellipsis" }}>ecessidade</small></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(itemsPaginados[pagina] || []).map((item, i) => (
                        <TableRow key={i}>
                            <TableCell align="left">
                                {item.materialNome} <span style={{ color: 'gray' }}>{item.materialEspecificacao ? '- ' + item.materialEspecificacao : null}</span>
                            </TableCell>
                            <TableCell component="th" scope="item" align="center">
                                {item.localNome}
                            </TableCell>
                            <Hidden mdDown>
                                <TableCell align="right">{item.consumo}</TableCell>
                                <TableCell align="right">{item.otimo}</TableCell>
                                <TableCell align="right">{item.bom}</TableCell>
                                <TableCell align="right">{item.regular}</TableCell>
                                <TableCell align="right">{item.inservivel}</TableCell>
                            </Hidden>
                            <TableCell align="right">{item.total}</TableCell>
                            <TableCell style={{ display: 'none' }} align="center" className={styles.celulaInput}>
                                <input className={styles.inputSemBorda} type="number" value={item.necessidade}
                                    onChange={handleChangeNecessidade(i, item)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container justifyContent="center" padding={2}>
                <Pagination count={itemsPaginados.length - 1} variant="outlined" shape="rounded" onChange={(v, p) => p && setPagina(p)} page={pagina} />
            </Grid>
        </Paper>
    )
}

export function TelaResumo() {
    let [items, setItems] = useState<ItemResumo[]>([]);
    let [filtroPastas, setFiltroPastas] = useState<{ [id: string]: boolean }>({});
    let [filtroMateriais, setFiltroMateriais] = useState('');

    const handleChangeNecessidadeDoItem = (index: number, qnt: number) => {
        setItems(items => {
            const novoItems = [...items];
            const item = new ItemResumo();
            Object.assign(item, { ...novoItems[index] });
            item.necessidade = qnt;
            novoItems[index] = item;
            ConsumoEmEstoqueService.salvaNecessidade(
                item.localId,
                item.materialNome,
                item.materialEspecificacao,
                item.necessidade
            ).catch(err => console.error(err))
            return novoItems;
        });
    };

    useEffect(() => {
        ConsumoEmEstoqueService.getAllAtualizado().then(items => Promise.all(items.map(ConsumoEmEstoqueService.intoItemResumo)).then(async items => {
            if (!LoginService.temPermissao('Gestor Materiais')) {
                const pastasDoUsr = await PastaService.pastasDoUsrLogado();
                const pastasIds = pastasDoUsr.map(p => p.id)
                items = items.filter(item => pastasIds.includes(item.localId))
            }

            setItems(items);
            const idsPastas = semRepeticoes(items.map(item => item.localId));

            const filtroPastas: { [id: string]: boolean } = {};
            for (let id of idsPastas) {
                filtroPastas[id] = true;
            }

            setFiltroPastas(filtroPastas);
        }))
    }, []);

    const contexto = useMemo(
        () => ({
            filtroPastas,
            setFiltroPastas,
            filtroMateriais,
            setFiltroMateriais
        }),
        [filtroPastas, filtroMateriais]
    );

    return (
        <ContextoTelaResumo.Provider value={contexto as any}>
            <ExibeTelaResumo items={items} handleChangeNecessidadeDoItem={handleChangeNecessidadeDoItem} />
        </ContextoTelaResumo.Provider>
    )
}
