import { http } from '../../util/ferramentas';
import { CadastroMaterialConsumo, CadastroMaterialConsumoService } from './cadastro-consumo';

export interface RecebimentoMaterialConsumo {
    material: number;
    data: string;
    local: string;
    quantidade: number;
    fonte?: string;
    notaEmpenho?: string;
    obs: string;
    responsavel: number;
}

class ClasseRecebimentoMaterialConsumoService {
    todos: { [id: number]: RecebimentoMaterialConsumo } = {};

    async salvarCadastroERecebimento(cadastro: CadastroMaterialConsumo, recebimento: RecebimentoMaterialConsumo) {
        if (cadastro.id === 0) {
            cadastro = await CadastroMaterialConsumoService.salvar(cadastro);
        }

        recebimento.material = cadastro.id;
        recebimento = await http.post('/api/recebimento-material-consumo', recebimento).then(resp => resp.data);
    }
}

export const RecebimentoMaterialConsumoService = new ClasseRecebimentoMaterialConsumoService();
