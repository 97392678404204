import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import { CadastroMaterialConsumo } from "../entidades/materiais/cadastro-consumo";
const PizZipUtils = require("pizzip/utils/index.js");

export type DadosTermoDeEntrega = {
    id: number,
    origem: string,
    destino: string,
    responsavel: string,
    recebedor: string,
    data: string,
    items: { num: number, material: CadastroMaterialConsumo, qnt: number }[],
    observacoes: string
}

export function imprimeTermoDeEntrega(termo: DadosTermoDeEntrega) {
    PizZipUtils.getBinaryContent('/modelo-termo-de-entrega.docx', (erro: any, conteudo: any) => {
        if (erro) {
            console.log(erro);
            return;
        }

        const zip = new PizZip(conteudo);
        const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
        });

        doc.render(termo);

        const buf = doc.getZip().generate({ type: "blob" });
        
        saveAs(buf, "termo de entrega.docx");
    });
}
