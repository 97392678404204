import { Download } from "@mui/icons-material";
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material";
import { format } from 'date-fns';
import { useEffect, useState } from "react";
import { LoginService, usePermissoesUsrLogado, useUsuarioLogado } from "../../../auth";
import { ApiMaterialPermanente, NotaPublicada } from "../../../entidades/materiais/permanente";
import { Pasta, PastaService } from "../../../entidades/pasta";
import { Unidade, UnidadeService } from "../../../entidades/unidades";
import { useUsuario } from "../../../entidades/usuario";

interface MaterialPermanente {
    cp: string
    descricao: string
}

export function TelaRecebimentoPermanente() {
    const [cp, set_cp] = useState('')
    const [num_nota_bam, set_num_nota_bam] = useState(0)
    const [nota_bam, set_nota_bam] = useState<NotaPublicada>()
    const [anexo_bam, set_anexo_bam] = useState(0)
    const [material, set_material] = useState<MaterialPermanente | null>()
    const [unidades, set_unidades] = useState<Unidade[]>([])
    const [destino, set_destino] = useState<Unidade>()
    const permissoes = usePermissoesUsrLogado() || []
    const usr = useUsuarioLogado()
    //const unidades = UnidadeService.get_lista(usePermissoesUsrLogado()?.map(p => p.obmId) || [])

    useEffect(() => {
        /*PastaService.pastasDoUsrLogado()
            .then(pastas => set_unidades(pastas.filter(p => p.tipo === 'orgao-detentor')))*/
        const ids = permissoes.filter(p => p.privNome === 'Agente Almox').map(p => p.obmId)
        UnidadeService.get_lista(ids).then(set_unidades)
    }, [permissoes])

    const buscar_cp = () => {
        ApiMaterialPermanente.buscar_por_cp(cp).then(set_material)
    }
    const buscar_nota = () => {
        ApiMaterialPermanente.buscar_nota(num_nota_bam).then(set_nota_bam)
    }

    const receber_material = () => {
        if (!!destino && !!num_nota_bam && !!usr) {
            ApiMaterialPermanente.receber_material(cp, destino?.id, num_nota_bam, usr.id, anexo_bam)
        }
    }

    return <Paper style={{ padding: 10, marginTop: 20 }}>
        <h3>Recebimento de Material Permanente</h3>

        <Grid container flexGrow={1} spacing={1}>
            <Grid xs={12} md={6} item flexDirection="column" display="flex">
                <FormControl fullWidth size="small">
                    <InputLabel>Destino</InputLabel>
                    <Select
                        label="Destino"
                        value={destino?.nomeExibicao || ''}
                    >
                        {unidades.map(u => <MenuItem key={u.id} onClick={() => { set_destino(u); console.log(u) }} value={u.nomeExibicao}>{u.nomeExibicao}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField label="Nota de publicação do BAM"
                    value={num_nota_bam}
                    onChange={ev => set_num_nota_bam(+ev.target.value)}
                    onBlur={buscar_nota}
                    type="number"
                    fullWidth
                    margin="normal"
                    size="small" required />
                {!!nota_bam && nota_bam.ID && <Paper variant="outlined" sx={{ marginTop: 1, padding: 1 }}>
                    <p>
                        <b>BOLETIM {nota_bam.PUBLICACAO_TIPO} Nº{nota_bam.PUBLICACAO_NUMERO}</b>
                        <IconButton size="small" color="primary"
                            href={"https://sistemas.bombeiros.ms.gov.br/ws-alfresco/arquivo/" + nota_bam.PUBLICACAO_ARQUIVO}>
                            <Download />
                        </IconButton>
                        <br />
                        {format(new Date(nota_bam.PUBLICACAO_DATA), 'dd/MM/yyyy')}
                        <br />
                        {nota_bam.NOME_EXIBICAO}
                    </p>
                    <div style={{ textAlign: 'left' }}>
                        Nota nº<b>{nota_bam.ID}</b>
                    </div>
                    <div
                        style={{ textAlign: 'justify' }}
                        dangerouslySetInnerHTML={{ __html: nota_bam.CONTEUDO }} />
                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                        {nota_bam.ANEXOS.map(a => <label key={a.ID}>
                            <input
                                type="radio" value={a.ID} checked={a.ID == anexo_bam}
                                onChange={ev => set_anexo_bam((ev.target as any).value)}
                            />
                            Anexo {a.NOME}
                            <IconButton size="small" color="primary"
                                href={"https://sistemas.bombeiros.ms.gov.br/ws-alfresco/arquivo/" + a.UPLOAD_ID}>
                                <Download />
                            </IconButton>
                        </label>)}
                        <label>
                            <input type="radio" value={0} checked={0 == anexo_bam}
                                onChange={ev => set_anexo_bam(0)}
                            />
                            O bam não consta como anexo da nota
                        </label>
                    </div>
                </Paper>}
            </Grid>
            <Grid xs={12} md={6} item flexDirection="column" display="flex">
                <TextField label="CP"
                    value={cp}
                    error={material === null}
                    onChange={ev => set_cp(ev.target.value)}
                    onBlur={buscar_cp}
                    fullWidth
                    size="small" required />
                {!!material && !!material.cp && <Paper variant="outlined" sx={{ marginTop: 2, padding: 1 }}>
                    <b>Descrição:</b>
                    <p>
                        {material.descricao}
                    </p>
                </Paper>}
            </Grid>
            <Grid xs={12} item justifyContent="left" display="flex">
                <Button
                    variant="outlined"
                    size="small"
                    onClick={receber_material}
                    disabled={
                        !nota_bam || !nota_bam.ID || !destino || !destino.id || !material || !material.cp}
                >
                    Concluir
                </Button>
            </Grid>
        </Grid>
    </Paper>
}
