import { Add, Close } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Pagination, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CadastroMaterialConsumo, CadastroMaterialConsumoService } from "../entidades/materiais/cadastro-consumo";
import { ConsumoEmEstoque, ConsumoEmEstoqueService } from "../entidades/materiais/estoque-consumo";
import { normalizado } from "../util/ferramentas";

function ExibeMaterial(props: { material: CadastroMaterialConsumo, qnt: number, exibeQnt?: boolean, filtro: string, onAdicionou: (material: CadastroMaterialConsumo) => void }) {
    const { material, qnt, onAdicionou, exibeQnt } = props;
    return (
        <TableRow>
            <TableCell align="right" component="th"><b>{material.nome}</b></TableCell>
            <TableCell align="left">{material.especificacao || ''}</TableCell>
            {exibeQnt && <TableCell align="right">{qnt}</TableCell>}
            <TableCell>
                <IconButton size="small" color="primary" onClick={() => onAdicionou(material)}>
                    <Add />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export function BuscaMaterialConsumoDisponivel(props: { pasta: string, adicionar: (material?: CadastroMaterialConsumo) => void }) {
    const { pasta, adicionar } = props;
    const [items, setItems] = useState<ConsumoEmEstoque[]>([]);
    const [materiais, setMateriais] = useState<{ [id: number]: CadastroMaterialConsumo }>({})
    const [filtro, setFiltro] = useState('');
    const [pagina, setPagina] = useState(0);

    useEffect(() => {
        ConsumoEmEstoqueService.conteudoDaPasta(pasta).then(async items_ => {
            await CadastroMaterialConsumoService.buscaEmLote(items_.map(i => i.material)).then(setMateriais)
            setItems(items_);
        })
    }, [pasta])

    const handleMudaFiltro = (str: string) => {
        setFiltro(normalizado(str));
        setPagina(0);
    }

    const itemsFiltrados = items.filter(item => normalizado(materiais[item.material].nome).includes(filtro) || normalizado(materiais[item.material].especificacao || '').includes(filtro))
    let paginas = _.chunk(itemsFiltrados, 10);
    if (paginas.length === 0) {
        paginas = [[]]
    }

    return (
        <Box>
            <Box textAlign="end">
                <IconButton onClick={() => adicionar()}>
                    <Close />
                </IconButton>
            </Box>
            <Container>
                <h4>Seleção Material</h4>

                <TextField label="Filtro" fullWidth={true} size="small"
                    value={filtro} onChange={(ev) => handleMudaFiltro(ev.target.value)} />
            </Container>
            <Table size="small">
                <TableBody>
                    {paginas[pagina].map(item => <ExibeMaterial key={item.material} material={materiais[item.material]} qnt={item.total} onAdicionou={adicionar} filtro={filtro} exibeQnt />)}
                </TableBody>
            </Table>
            <Grid container justifyContent="center" padding={1}>
                <Pagination count={paginas.length-1} page={pagina+1} onChange={(ev, v) => {setPagina(v-1)}} variant="outlined" shape="rounded" />
            </Grid>
        </Box>
    )
}
