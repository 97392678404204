import axios from "axios";

export interface MaterialPermanente {
    cp: string
    descricao: string
}

export type NotaPublicada = {
    ID: number
    CONTEUDO: string
    ENVIADOPUBLICACAOPOR_ID: number
    PUBLICACAO_NUMERO: number
    PUBLICACAO_TIPO: string
    PUBLICACAO_DATA: string
    PUBLICACAO_ARQUIVO: string
    NOME_EXIBICAO: string
    ANEXOS: {
        ID: number
        NOME: string
        UPLOAD_ID: string
    }[]
}

export module ApiMaterialPermanente {
    export function buscar_por_cp(cp: string) {
        return axios.get<MaterialPermanente>('/api/material-permanente/'+cp).then(r => r.data)
    }
    export function buscar_nota(id: number): Promise<NotaPublicada> {
        return axios.get<NotaPublicada>('/api/material-permanente/nota/'+id).then(r => r.data)
    }

    export function receber_material(cp: string, unidade: number, nota_bam: number, autor: number, anexo_bam?: number): Promise<NotaPublicada> {
        const dto: {cp: string, unidade: number, nota_bam: number, autor: number, anexo_bam?: number} = {
            cp, unidade, nota_bam, autor, anexo_bam
        }
        return axios.post('/api/material-permanente/receber', dto).then(r => r.data)
    }
}

/*
export class MaterialPermanenteInsert {
    nome: string = '';
    cp?: string = '';
    sispat?: string = '';
    codCBMMS?: string = '';
    pasta?: string;
    situacao: string = 'Ótimo';
    descricao: string = '';
}

export class MaterialPermanente extends MaterialPermanenteInsert {
    id: number = 0;
}

export interface HistoricoMaterialPermanente {
    id: number;
    material: number;
    titulo: string;
    conteudo: string;
    data: string;
    autor: Usuario;
    pai: number;
    raiz: number;
    respostas: HistoricoMaterialPermanente[];
}

class ClasseMaterialPermanenteService {

    async buscaPorId(id: number) {
        try {
            return axios.get<MaterialPermanente>('/api/material-permanente/'+id).then(resp => resp.data);
        } catch (error) {
        }
    }

    async cadastrar(material: MaterialPermanenteInsert) {
        try {
            return axios.post<MaterialPermanente>('/api/material-permanente', material).then(resp => resp.data);
        } catch (error) {
            //console.log(error);
        }
    }
}

export const MaterialPermanenteService = new ClasseMaterialPermanenteService();
*/