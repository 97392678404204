import { useEffect, useState } from "react";
import { http } from "../util/ferramentas";

export interface Usuario {
    id: number
    usrId?: number
    nome: string
    hierarquia: string
    nomeDeGuerra: string
    telefones: string[]
    unidadeId: number
    foto?: string;
}

class ClasseUsuarioService {
    usuarios: { [id: number]: Usuario } = {};
    inicializado: Promise<any> = this.carregaTodos();

    async carregaTodos() {
        let tds = await http.get<Usuario[]>(`/api/usuario`).then(resp => resp.data);
        for (let usr of tds) {
            this.usuarios[usr.id] = usr;
        }
    }

    async getAll() {
        await this.inicializado;
        return Object.values(this.usuarios);
    }

    async getById(id: number | undefined) {
        if (id === undefined || id === 0) {
            return;
        }
        await this.inicializado;
        return this.usuarios[id];
    }

}

export const UsuarioService = new ClasseUsuarioService();

export function useUsuario(id: number | undefined) {
    let [pasta, setPasta] = useState<Usuario>({
        id: 0,
        nome: '',
        hierarquia: '',
        nomeDeGuerra: '',
        telefones: [],
        unidadeId: 0,
        foto: '',
    });
    useEffect(() => {
        UsuarioService.inicializado.then(() => {
            UsuarioService.getById(id).then(p => setPasta(p || pasta));
        })
    })

    return pasta;
}
