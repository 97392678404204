import { Dialog, DialogTitle, List, ListItem } from "@mui/material";
import { LoginService } from "../auth";
import { usePasta } from "../entidades/pasta";

function ExibeOpcaoPasta(props: { pastaId: string, handleClose: (value?: string) => void }) {
    const { pastaId, handleClose } = props;
    const pasta = usePasta(pastaId);

    return (
        <ListItem button onClick={() => handleClose(pastaId)}>
            {pasta.nome}
        </ListItem>
    )
}

export function SelecionaUnidade(props: { open: boolean, handleClose: (value?: string) => void, titulo?: string }) {
    const { open, handleClose } = props;
    const titulo = props.titulo || 'Selecione a Unidade de Origem';

    const opcoes = LoginService.permissoes.filter(p => p.appNome === 'materiais-almox').map(p => p.obmId + 'u');

    return (
        <Dialog onClose={() => handleClose(undefined)} open={open}>
            <DialogTitle>{titulo}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {opcoes.map((opcao) => <ExibeOpcaoPasta key={opcao} handleClose={handleClose} pastaId={opcao} />)}
            </List>
        </Dialog>
    )
}