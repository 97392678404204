import { Pasta } from "../pasta";

export class ItemResumo {
    constructor(
        public localId: Pasta['id'] = "",
        public localNome: string = "",
        public materialId: string = "",
        public materialNome: string = "",
        public materialEspecificacao: string = "",
        public consumo: number = 0,
        public otimo: number = 0,
        public bom: number = 0,
        public regular: number = 0,
        public inservivel: number = 0,
        public necessidade: number = 0,
    ) { }

    get total(): number {
        return this.consumo
            + this.otimo
            + this.bom
            + this.regular
            + this.inservivel;
    }
}

/*
async function consumoEmEstoqueToItemResumo(c: ConsumoEmEstoque) {
    let item = new ItemResumo();
    return item;
}

class ClasseItemResumoService { 
    async getAll() {
        let [consumo] = await Promise.all([ConsumoEmEstoqueService.getAll()]);
        return await Promise.all([...consumo.map(consumoEmEstoqueToItemResumo)]);
    }
}
*/

/*class ClasseItemResumoServiceMock {
    async getAll() {
        let todos = [
            new ItemResumo('194u', 'Almox', '1c', 'SOLUÇÃO FISIOLÓGICA', 'Frasco 100ml', 20),
            new ItemResumo('194u', 'Almox', '2c', 'LUVA DE LATEX', 'CX 100u Tam G', 0, 2, 3, 4, 5, 6),
            new ItemResumo('194u', 'Almox', '1p', 'HT Satélite'),
            new ItemResumo('194u', 'Almox', '2p', 'HT'),
            new ItemResumo('22u', '12º SGBM/Ind', '2p', 'HT'),
        ];
        todos = [
            ...todos,
            //...todos, ...todos,...todos,...todos,...todos
        ]
        return todos
    }

    async salvaNecessidade(pasta: ItemResumo['localId'],
                           materialNome: ItemResumo['materialNome'],
                           materialEspecificacao: ItemResumo['materialEspecificacao'],
                           qnt: number) {

                        
    }
}*/

//export const ItemResumoService = new ClasseItemResumoServiceMock();
